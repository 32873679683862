(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

jQuery(document).ready(function ($) {
  // Check if user allows cookies
  CheckCookie(); // Show fixed header

  var headerHeight = $('.c-header').outerHeight();
  $(window).scroll(function () {
    $(window).scrollTop() >= headerHeight ? $('.c-header').addClass('is-fixed') : $('.c-header').removeClass('is-fixed');
  }); // Open mobile menu

  $('.c-hamburger').click(function () {
    $(this).toggleClass('is-open');
    $('body').toggleClass('is-open-sidebar');
  }); // Close mobile menu

  $(window).resize(function () {
    if ($(window).width() > 991) {
      $('.c-hamburger').removeClass('is-open');
      $('body').removeClass('is-open-sidebar');
    }
  }); // Active navigation item

  var lastId,
      topMenu = $(".c-nav-primary"),
      topMenuHeight = $(".c-header").outerHeight() + 1,
      menuItems = topMenu.find("a"),
      scrollItems = menuItems.map(function () {
    var item = $($(this).attr("href"));

    if (item.length) {
      return item;
    }
  });
  menuItems.click(function (e) {
    $('.c-hamburger').removeClass('is-open');
    $('body').removeClass('is-open-sidebar');
    var href = $(this).attr("href"),
        offsetTop = href === "#" ? 0 : $(href).offset().top - topMenuHeight + 1;
    $('html, body').stop().animate({
      scrollTop: offsetTop
    }, 850);
    e.preventDefault();
  });
  $(window).scroll(function () {
    var fromTop = $(this).scrollTop() + topMenuHeight;
    var cur = scrollItems.map(function () {
      if ($(this).offset().top < fromTop) return this;
    });
    cur = cur[cur.length - 1];
    var id = cur && cur.length ? cur[0].id : "";

    if (lastId !== id) {
      lastId = id;
      menuItems.parent().removeClass("active").end().filter('a[href="#' + id + '"]').parent().addClass("active");
    }
  }); // Slickslider

  $('.slider').slick({
    dots: false,
    infinite: true,
    centerMode: true,
    speed: 300,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [{
      breakpoint: 1200,
      settings: {
        slidesToShow: 3
      }
    }, {
      breakpoint: 768,
      settings: {
        slidesToShow: 2
      }
    }, {
      breakpoint: 480,
      settings: {
        slidesToShow: 1
      }
    }]
  });
  $('.slider-small').slick({
    dots: false,
    infinite: false,
    centerMode: false,
    speed: 300,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [{
      breakpoint: 1200,
      settings: {
        slidesToShow: 4
      }
    }]
  }); // Lightbox

  $('.venobox').venobox(); // Tabs

  $('.tab-headers .tab-header').on('click', function (e) {
    e.preventDefault();
    var t = $(this).attr('id');

    if (!$(this).hasClass('active')) {
      $('.tab-headers .tab-header').removeClass('active');
      $(this).addClass('active');
      $('.tab-contents .tab-content').removeClass('active');
      $('#' + t + 'C').addClass('active');
    }
  }); // Read more

  $('.read-more-button').on('click', function (e) {
    e.preventDefault();
    var text = $(this).parent().find('.text');

    if (text.hasClass('short')) {
      text.removeClass('short');
      $(this).html('Lees minder');
    } else {
      text.addClass('short');
      $(this).html('Lees meer');
    }
  }); // Only dates in future for date picker

  $(function () {
    var dtToday = new Date();
    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = '0' + month.toString();
    if (day < 10) day = '0' + day.toString();
    var minDate = year + '-' + month + '-' + day;
    $('input#date').attr('min', minDate);
  }); // Header style

  if ($('.c-header-background').length) {
    $('.c-header .c-header__menu .c-nav-primary ul li a, .c-header .c-header__menu .c-nav-language a').css('text-shadow', 'none');
  } // Cookies


  $("#cookieButton").click(function () {
    $.cookie('cookiesAccepted', '1', {
      expires: 365,
      path: '/'
    });
    $("#cookieConsent").hide(400);
    CheckCookie();
  });
  $("#closeCookieConsent").click(function () {
    $.cookie('cookiesAccepted', '0', {
      expires: 7,
      path: '/'
    });
    $("#cookieConsent").hide(400);
    CheckCookie();
  }); // Check cookie status

  function CheckCookie() {
    if ($.cookie('cookiesAccepted') == null) {
      // Not set, show Cookie Consent
      $("#cookieConsent").fadeIn(400);
    } else if ($.cookie('cookiesAccepted') == "1") {
      // Accepted, add Google Analytics
      var s = document.createElement("script"),
          x = document.createElement("script");
      s.type = "text/javascript";
      s.src = "/assets/analytics.js";
      x.async = true;
      x.src = "https://www.googletagmanager.com/gtag/js?id=ID_COMES_HERE";
      $("head").prepend(s);
      $("head").prepend(x);
    } else if ($.cookie('cookiesAccepted') == "0") {// Denied, will show again in a week
    }
  } // Animate on Scroll


  AOS.init({
    once: true
  });
});

},{}]},{},[1]);
